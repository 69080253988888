/* TODO
** This file contains overrides for the annotation tools which should be in the annotation package... */

#map {
    height: 100vh;
    width: 100vw;
}

.leaflet-map {
    background: #222327 !important;
}

@media only screen and (max-width: 700px) {
    .leaflet-control-layers-toggle {
        display: none !important;
    }
}

.leaflet-control {
    cursor: pointer;
    outline: none !important;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    border: none !important;
}

.leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded {
    padding: 12px;

    margin-bottom: -5px;
    padding-bottom: 0;
    z-index: 5000;
    background: #6E322B !important;
}

.leaflet-control-layers-list label span span,
.leaflet-control-layers-base label div span,
.leaflet-control-layers-overlays label div span {
    color: white;
    font-weight: normal;
    font-family: 'Quantico', sans-serif !important;
    padding-right: 5px;
}

.leaflet-control-container .leaflet-bottom.leaflet-left a.leaflet-control-layers-toggle {
    display: none;
    margin: 0 !important;
    padding: 0 !important;
}

.leaflet-control-container .leaflet-bottom.leaflet-left .leaflet-control-layers.leaflet-control {
    display: none !important;
}

/* MARKERS */
.marker-small {
    opacity: 0.9;
    z-index: -900 !important;
}
.marker-large {
    opacity: 1;
}

@media only screen and (max-width: 700px) {
    .leaflet-control-attribution {
        display: none !important;
    }
}

/* TODO some of the styles for the annotation textfield popup have been overriden here but should be in the package */
.text-field-popup .leaflet-popup-content-wrapper {
    width: 297px;
}
.text-field-specify-text {
    width: 267px !important;
}

/* This is for the satellite AOI to hide it in the annotations.. */
button#satellite-aoi-button {
    visibility: hidden !important;
    width: 0 !important;
    height: 0 !important;
    margin: 0 !important;
}

.leaflet-cluster-span {
    display: block;
    height: 24px;
    margin-top: 3px;
    font-weight: bold;
}

.grouped-polygon-popup-marker-icon {
    border-radius: 32px;
    border: 1px solid rgba(238, 227, 8, 0.8);
    background: radial-gradient(circle closest-side, #000 0%, #000 80%, #eed926 90%, #000 100%);
}

.grouped-polygon-popup-marker-icon.highlighted {
    border-radius: 32px;
    border: 3px solid rgba(238, 227, 8, 0.8);
    background: radial-gradient(circle closest-side, #000 0%, #000 80%, #eed926 90%, #000 100%);
}

.leaflet-control,
.leaflet-control-layers-base,
.leaflet-control-layers {
    user-select: none;
}

.pinned-tile-layer {
    z-index: 1000 !important;
}

/* TODO this forces the size of the annotations popup window to be larger, should be handled in the package */
.text-field-popup .leaflet-popup-content-wrapper {
    width: 297px !important;
}
.text-field-specify-text {
    width: 263px !important;
}

.leaflet-cluster-marker-dot {
    background-color: rgb(51, 136, 255);
    border-radius: 4px;
    width: 8px;
    height: 8px;
}

.leaflet-cluster-marker-highlighted-dot {
    background-color: #eed926;
    border-radius: 6px;
    width: 12px;
    height: 12px;
}