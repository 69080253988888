 .leaflet-control.leaflet-control-scale {
    margin-left: 110px;
} 

.leaflet-control-scale-line {
    border: 2px solid rgba(255, 255, 255, 0.85);
    border-top: none;
    white-space: nowrap;
    background: none;
    text-shadow: none;
    color: white;
    font-size: 10px !important;
    text-shadow: 1px 0 0 #808080, 0 -1px 0 #808080, 0 1px 0 #808080, -1px 0 0 #808080;
}

.leaflet-control-scale-line:not(:first-child) {

    border-top: 2px solid rgba(255, 255, 255, 0.85);
    margin-top: -2px;
}

