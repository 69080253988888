html {
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
tr,
td,
span,
strong,
div,
input {
    font-family: 'Quantico', sans-serif !important;
    color: #6E322B;
}

body {
    overflow-x: hidden;
    min-height: 100vh;
    height: 100%;
    background-color: #E9D7AD;
}

h1 {
    font-size: 2.0rem;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    font-size: 1.3rem;
}

h4 {
    font-size: 1.0rem;
}

h5 {
    font-size: 0.8rem;
}

h6 {
    font-size: 0.7rem;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar,
::-ms-scrollbar {
    width: 12px !important;
}

::-webkit-scrollbar-track,
::-ms-scrollbar-track {
    background-color: #6E322B;
}

::-webkit-scrollbar-thumb,
::-ms-scrollbar-thumb {
    background-color: #6E322B;
    border-radius: 20px;
    border: 1px solid #6E322B;
}

body {
    scrollbar-color: #6E322B rgba(0, 0, 0, 0.1);
}


  /* Tooltip */
.tooltip-inner {
    max-width:250px;
    color:  #6E322B;
    background-color: #e9d7ad;
    border-radius: 0.25rem;
    border: 1px solid #000;
}

