.annotation-tool-horizontal-distance-marker span{
    background: black;
    color: white;
    width: auto !important;
    height: auto !important;
    padding: 0px 3px !important;
}

.annotation-tool-vertical-distance-marker {
    width: auto !important;
    height: auto !important;
}

.annotation-tool-vertical-distance-marker span {
    display: block;
    background: black;
    color: white;

    padding: 0px 3px !important;
    -webkit-transform: rotate(90deg); /* Safari and Chrome */
    -moz-transform: rotate(90deg);   /* Firefox */
    -ms-transform: rotate(90deg);   /* IE 9 */
    -o-transform: rotate(90deg);   /* Opera */
    transform: rotate(90deg);
}

.annotation-tool-area-marker {
    pointer-events: none;
    text-align: center;
}

.annotation-tool-area-marker span {
    background: rgba(0, 0, 0, 0.75);
    font-size: 1.0rem;
    color: white;
    padding: 0px 3px !important;
    pointer-events: none;
    text-wrap: nowrap
}


.annotation-tool-corner-handle  {
    background: transparent;
    width: 16px !important;
    height: 16px !important;  
}

.annotation-tool-corner-marker  {
    background: #e9d7ad;
    border: 2px solid #6e322b;
}

.annotation-tool-corner-marker.red  {
    background: red;
    border: 2px solid red;
}